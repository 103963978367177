import React from "react"
import "./img-sections.css"
import Img from "gatsby-image"

const ImgSections = ({ sectionData }) => {
  const h2OverBorderLeft = () => {
    return (
      <span className="hidden lg:block h-3gap relative">
        <span className="absolute right-0 top-0 w-screen h-1gap border border-black border-t-1gap xl:mr-0"></span>
        <span className="hidden xl:block absolute right-0 top-0 border-black border-1gap rounded-full -mt-1/2gap mr-0 xl:-mr-1gap"></span>
      </span>
    )
  }

  const sideImgBorderRight = () => {
    return (
      <>
        <span className="hidden xl:block absolute left-0 top-0 ml-2gap xl:ml-1col1gap h-full border border-black border-l-1gap"></span>
        <span className="hidden lg:block absolute left-0 top-0 ml-2gap xl:ml-1col1gap w-screen border border-black border-t-1gap"></span>
      </>
    )
  }

  const h2OverBorderRight = () => {
    return (
      <span className="hidden lg:block h-3gap relative">
        <span className="absolute left-0 top-0 w-screen h-1gap border border-black border-t-1gap xl:ml-0"></span>
        <span className="hidden xl:block absolute left-0 top-0 border-black border-1gap rounded-full -mt-1/2gap -ml-1gap xl:-ml-1gap"></span>
      </span>
    )
  }

  const sideImgBorderLeft = () => {
    return (
      <>
        <span className="hidden xl:block absolute right-0 top-0 h-full border border-black border-r-1gap"></span>
        <span className="hidden lg:block absolute right-0 top-0 w-screen border border-black border-t-1gap"></span>
      </>
    )
  }

  return sectionData.map((section, i) => {
    const odd = i % 2 === 0
    const even = !odd
    const className_section = `flex -ml-2gap xl:-ml-1col1gap flex-col md:flex-row${
      even ? "-reverse" : ""
    }`
    const className_imgWrapper = `over:w-5col4gap ${
      even ? "over:-ml-1col1gap" : ""
    }`
    const className_richText = () => {
      return { __html: section.text }
    }
    return (
      <div key={i}>
        <div className="border-black border-t-1/2gap pt-1gap lg:hidden"></div>
        <section className={className_section}>
          <div className="flex-grow pl-2gap xl:pl-1col1gap pb-1gap lg:pb-1col">
            <div className="text-2xl lg:text-4xl leading-tight relative pb-1gap font-bold">
              {odd ? h2OverBorderLeft() : h2OverBorderRight()}
              {section.heading && <h2>{section.heading}</h2>}
            </div>
            <div
              className="yw-RichTextSlices"
              dangerouslySetInnerHTML={className_richText()}
            ></div>
          </div>
          <div className="flex-grow-0 hidden sm:block relative pb-2gap">
            <div className="pl-2gap xl:pl-1col1gap w-full md:w-2col3gap lg:w-3col2gap xl:w-5col4gap mx-auto">
              {section.img && (
                <div className={className_imgWrapper}>
                  <Img
                    className="block w-full border-black border-1/2gap lg:border-1gap"
                    fluid={section.img.fluid}
                    alt={section.img.alt}
                  />
                </div>
              )}
            </div>
            {odd ? sideImgBorderRight() : sideImgBorderLeft()}
          </div>
        </section>
      </div>
    )
  })
}

export default ImgSections
